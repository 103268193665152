import { WEBSITE_VISITORS_REPORT_ITEMS } from "@/utils/constants";
import { useState } from "react";
import { ReactComponent as PlanetIcon } from "@/assets/image/icons/planet.svg";

const tableCells = {
    [WEBSITE_VISITORS_REPORT_ITEMS.TOP_COMPANIES_BY_VISITS]: [
        // { head: "checkbox", body: "checkbox" },
        { head: "Company Name", body: "companyName" },
        { head: "Location", body: "locationCountry" },
        //
        { head: "Job Level", body: "jobLevel" },
        { head: "Job Role", body: "jobRole" },
        { head: "Industry", body: "companyIndustry" },
        { head: "Visits", body: "count" },
        { head: "Confidence Interval", body: "confidenceInterval" },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.INDUSTRIES]: [
        { head: "Industry", body: "industry" },
        { head: "Companies", body: "companies" },
        { head: "Countries", body: "countries" },
        { head: "Locations", body: "locations" },
        { head: "Visitors", body: "visitors" },
        { head: "Visits", body: "count" },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.EMPLOYEE_RANGES]: [
        { head: "Employees Range", body: "employeeRange" },
        { head: "Companies", body: "companies" },
        { head: "Countries", body: "countries" },
        { head: "Visitors", body: "visitors" },
        { head: "Visits", body: "count" },
    ],
    [WEBSITE_VISITORS_REPORT_ITEMS.LOCATIONS]: [
        { head: "Employee Location", body: "employeeLocation" },
        { head: "Companies", body: "companies" },
        { head: "Industries", body: "industries" },
        { head: "Visitors", body: "visitors" },
        { head: "Visits", body: "count" },
    ],
};

export default function ResultsTable({ data, currentTab, filterProps, setFilterProps }) {
    const [imageLoaded, setImageLoaded] = useState({});
    const [checkedItems, setCheckedItems] = useState(filterProps.reduce((acc, obj) => ({ ...acc, [obj.companyName]: true }), {}));

    const handleImageLoad = (index) => {
        setImageLoaded((prevState) => ({
            ...prevState,
            [index]: true,
        }));
    };

    const handleImageError = (index) => {
        setImageLoaded((prevState) => ({
            ...prevState,
            [index]: false,
        }));
    };

    const checkAllRecords = (event) => {
        if (event.target.checked) {
            data.forEach((item) => {
                setProspectFilters(item);
            });
        } else {
            setFilterProps([]);
            setCheckedItems([]);
        }
    };

    const getConfidenceIntervalStle = (val) => {
        let commonStyle = "p-3 h-6 flex items-center justify-center rounded-[4px] font-light capitalize whitespace-nowrap";
        if (val === "high") {
            commonStyle += " " + "bg-green-100 text-green-600";
        } else if (val === "moderate") {
            commonStyle += " " + "bg-violet-100 text-violet-600";
        } else if (val === "low") {
            commonStyle += " " + "bg-orange-100 text-orange-600";
        } else if (val === "very low") {
            commonStyle += " " + "bg-red-200 text-red-600";
        }
        return <p className={`${commonStyle}`}>{val}</p>;
    };

    const setProspectFilters = (item) => {
        if (!filterProps.some((obj) => obj.companyName === item.companyName)) {
            const tempFilterProps = filterProps;
            tempFilterProps.push(item);
            setFilterProps(tempFilterProps);
            setCheckedItems((prevState) => ({
                ...prevState,
                [item.companyName]: !prevState[item.companyName],
            }));
        } else {
            const tempFilterProps = filterProps.filter((obj) => obj !== item);
            setFilterProps(tempFilterProps);
            setCheckedItems((prevState) => {
                const { [item.companyName]: _, ...rest } = prevState;
                return rest;
            });
        }
    };

    return (
        <table className="w-full border-2 rounded-2xl">
            <thead className="mb-8">
                <tr className="font-Outfit text-[14px] 2xl:text-[16px] text-stone-950 leading-[1.5]">
                    {tableCells[currentTab].map((cell, index) => (
                        <th
                            key={index}
                            className={`bg-stone-250 py-3 text-left px-2 ${
                                index === tableCells[currentTab].length - 1 && "rounded-r-[8px]"
                            } ${cell.head === "Visits" && "text-center"}`}
                        >
                            {cell.body === "companyName" ? (
                                <div className="flex gap-3">
                                    <input
                                        className="form-checkbox ml-2 text-blue-600"
                                        type="checkbox"
                                        checked={false}
                                        onChange={checkAllRecords}
                                        hidden={true}
                                    ></input>
                                    <p className="font-normal">{cell.head}</p>
                                </div>
                            ) : (
                                <p className="font-normal">{cell.head}</p>
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className={`font-Outfit text-[14px] 2xl:text-[16px] text-stone-950 leading-[1.5] border-b border-stone-250 ${
                            rowIndex === data.length - 1 && "border-none"
                        }`}
                    >
                        {tableCells[currentTab].map((cell, cellIndex) => (
                            <td key={`${rowIndex}-${cellIndex}`}>
                                <div
                                    className={`flex items-center gap-2 p-4 text-left ${
                                        (cell.body === "count" || cell.body === "personConfidence" || cell.body === "employees") &&
                                        "justify-center"
                                    }`}
                                >
                                    {cell.body === "companyName" && (
                                        <input
                                            type="checkbox"
                                            checked={checkedItems[item.companyName]}
                                            className="form-checkbox mr-4  text-blue-600"
                                            onChange={() => {
                                                setProspectFilters(item);
                                            }}
                                        ></input>
                                    )}
                                    {cell.body === "companyName" && (
                                        <div
                                            className={`w-6 h-6 flex items-center justify-center rounded-[4px] ${
                                                imageLoaded[rowIndex] ? "bg-white" : "bg-accents-purple"
                                            } text-wrap`}
                                        >
                                            <img
                                                src={`https://logo.clearbit.com/${item.companyWebsite}`}
                                                alt="Company Logo"
                                                onLoad={() => handleImageLoad(rowIndex)}
                                                onError={() => handleImageError(rowIndex)}
                                                style={{
                                                    display: imageLoaded[rowIndex] ? "block" : "none",
                                                }}
                                            />
                                            {!imageLoaded[rowIndex] && (
                                                <p className="uppercase text-[20px]">{item.companyName?.charAt(0)}</p>
                                            )}
                                        </div>
                                    )}
                                    {cell.body !== "companyWebsite" && cell.body !== "confidenceInterval" && (
                                        <p className="font-normal capitalize whitespace-nowrap text-pretty">{item[cell.body]}</p>
                                    )}
                                    {cell.body === "companyWebsite" && (
                                        <a
                                            href={`http://${item.companyWebsite}`}
                                            className="w-10 h-10 rounded-[8px] bg-stone-150 flex items-center justify-center"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <PlanetIcon />
                                        </a>
                                    )}
                                    {cell.body === "confidenceInterval" && getConfidenceIntervalStle(item[cell.body])}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
