import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactComponent as Sent } from "@/assets/image/ai_engagement/sent.svg";
import { ReactComponent as RenameIcon } from "@/assets/image/ai_engagement/renameIcon.svg";
import { ReactComponent as MailWaitIcon } from "@/assets/image/ai_engagement/mail-wait-icon.svg";
import { extractDateAndTime, toStylelessDocument } from "@/utils/common";
import useAIEngagement from "@/hooks/useAIEngagement";
import { toast } from "react-toastify";
import { Replied } from "@/components/icons/InboxIcon";

export default function AllActivities({ activities, setActivities }) {
    const [expanded, setExpanded] = useState(null);
    const [editActivityId, setEditActivityId] = useState(null);
    const [activityBackup, setActivityBackup] = useState({});
    const [loading, setLoading] = useState({ saving: false });
    const [datetime, setDatetime] = useState("");
    const [filter, setFilter] = useState("all");

    const { modifyScheduledEmail } = useAIEngagement();
    console.log("activities", activities);

    const handleRenameClick = (activityId) => {
        const activity = activities.find((act) => act.scheduledEmailId === activityId);
        setActivityBackup({ ...activity });
        setDatetime(activity.scheduledDateUTC ? activity.scheduledDateUTC.slice(0, 16) : "");
        setEditActivityId(activityId);
    };

    const handleSaveClick = async (activity) => {
        const updatedActivity = {
            ...activity,
            scheduledDateUTC: datetime + ":00Z",
        };
        setLoading((prev) => ({ ...prev, saving: true }));
        const promise = modifyScheduledEmail(updatedActivity);
        toast.promise(promise, {
            pending: "Updating activity...",
            success: {
                render(res) {
                    const newScheduledEmailId = res.data.scheduledEmailId;
                    setActivities((prevActivities) =>
                        prevActivities.map((act) =>
                            act.scheduledEmailId === activity.scheduledEmailId
                                ? { ...updatedActivity, scheduledEmailId: newScheduledEmailId }
                                : act
                        )
                    );
                    return "Activity updated successfully!";
                },
            },
            error: "Failed to update activity!",
        });

        setEditActivityId(null);
        setLoading((prev) => ({ ...prev, saving: false }));
    };

    const formatText = (htmlString) => {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = htmlString;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    };

    const convertToPlain = (htmlString) => {
        htmlString = toStylelessDocument(htmlString);
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = htmlString;
        let htmlText = tempDivElement.textContent || tempDivElement.innerText || "";
        return htmlText.replace(", ", "\n");
    };

    const handleCancelClick = () => {
        setActivities((prevActivities) =>
            prevActivities.map((activity) =>
                activity.scheduledEmailId === activityBackup.scheduledEmailId ? { ...activityBackup } : activity
            )
        );
        setEditActivityId(null);
    };

    const handleInputChange = (activityId, field, value) => {
        console.log("activityId", activityId, field, value);
        setActivities((prevActivities) =>
            prevActivities.map((activity) => (activity.scheduledEmailId === activityId ? { ...activity, [field]: value } : activity))
        );
    };

    const getCurrentDateTimeForInput = () => {
        const now = new Date();
        return new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString().slice(0, 16);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    let filteredActivities = activities.filter((activity) => {
        if (filter === "all") return true;
        return activity.status === filter;
    });

    // removing mailStatus undefined emails
    filteredActivities = filteredActivities.filter((activity) => activity.mailStatus !== undefined || activity.mailStatus !== null);

    return (
        <>
            <p className="text-3xl font-semibold">Overview</p>
            <div className="border-t border-stone-400 mt-0.5"></div>

            <div className="campaign-card mt-5">
                <div className="flex items-center">
                    <p className="sm:text-md font-semibold text-sm">All Activities</p>
                    <div className="ml-auto flex gap-2 items-center">
                        <p className="mb-0.5">Filter By:</p>
                        <Select
                            value={filter}
                            onChange={handleFilterChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Filter Activities" }}
                            className="text-sm"
                            sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: 0,
                                },
                                "& .MuiSelect-select": {
                                    padding: "4px 8px",
                                },
                            }}
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="sent">Sent</MenuItem>
                            <MenuItem value="scheduled">Scheduled</MenuItem>
                        </Select>
                    </div>
                </div>

                <div className="relative mt-4 flex flex-col gap-3">
                    <div className={`absolute left-[17.5px] top-4 border-l-2 border-dashed border-dgray-500 h-[94%]`}></div>

                    {filteredActivities.map((activity) => {
                        const dateTime = extractDateAndTime(activity.mailDate || activity.sentDateTime);
                        return (
                            <div className="flex w-[90%]" key={activity.scheduledEmailId}>
                                {activity.mailStatus == "sent" ? (
                                    <div className="flex items-center z-20 justify-center flex-shrink-0 w-9 h-9 bg-green-100 shadow-lg border border-stone-200 rounded-full">
                                        <Sent />
                                    </div>
                                ) : activity.mailStatus == "replied" ? (
                                    <div className="flex items-center z-20 justify-center flex-shrink-0 w-9 h-9 bg-blue-100 shadow-lg border border-stone-200 rounded-full">
                                        <Replied className="w-6 h-6" />
                                    </div>
                                ) : (
                                    <div className="flex items-center z-20 justify-center flex-shrink-0 w-9 h-9 bg-white shadow-lg border border-stone-200 rounded-full">
                                        <MailWaitIcon className="w-6 h-6" />
                                    </div>
                                )}
                                <div className="ml-5 w-full">
                                    <Accordion
                                        expanded={expanded === activity.scheduledEmailId}
                                        onChange={() =>
                                            setExpanded(expanded === activity.scheduledEmailId ? null : activity.scheduledEmailId)
                                        }
                                        sx={{
                                            backgroundColor: expanded === activity.scheduledEmailId ? "#F6F6F6" : "transparent",
                                            boxShadow: "none",
                                            "&:before": {
                                                display: "none",
                                            },
                                        }}
                                        className="border border-stone-400 !rounded-lg"
                                        key={activity.scheduledEmailId}
                                    >
                                        <AccordionSummary
                                            className="flex items-center cursor-pointer"
                                            expandIcon={null}
                                            sx={{
                                                ".MuiAccordionSummary-content.Mui-expanded": {
                                                    marginBottom: 0,
                                                    marginTop: "12px",
                                                },
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <div className="w-full">
                                                {activity.mailStatus == "sent" ? (
                                                    <div class="h-6 px-2 py-1 bg-green-100 mb-4 rounded justify-start items-center gap-0.5 inline-flex">
                                                        <div class="text-center text-green-600 text-sm font-normal font-outfit capitalize">
                                                            {activity.mailStatus}
                                                        </div>
                                                    </div>
                                                ) : activity.mailStatus == "replied" ? (
                                                    <div class="h-6 px-2 py-1 bg-blue-100 mb-4 rounded justify-start items-center gap-0.5 inline-flex">
                                                        <div class="text-center text-blue-600 text-sm font-normal font-outfit capitalize">
                                                            {activity.mailStatus}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div class="h-6 px-2 py-1 bg-gray-100 mb-4 rounded justify-start items-center gap-0.5 inline-flex">
                                                        <div class="text-center text-gray-600 text-sm font-normal font-outfit capitalize">
                                                            {activity.mailStatus}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="flex gap-2 text-sm text-dgray-500 item-center">
                                                    <p>{dateTime.date}</p>
                                                    {/* <p className="font-medium text-xs mt-[1px]">({activity.mailStatus})</p> */}
                                                    <p className="ml-auto">{dateTime.time}</p>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    {editActivityId === activity.scheduledEmailId ? (
                                                        <input
                                                            type="text"
                                                            value={activity.subject}
                                                            onChange={(e) =>
                                                                handleInputChange(activity.scheduledEmailId, "subject", e.target.value)
                                                            }
                                                            className="mt-3 text-md font-semibold p-1 bg-transparent outline-none w-full"
                                                            onClick={(e) => e.stopPropagation()}
                                                            onFocus={(e) => e.stopPropagation()}
                                                        />
                                                    ) : (
                                                        <p className="mt-3 text-md font-semibold">{activity.subject}</p>
                                                    )}
                                                    {expanded === activity.scheduledEmailId && activity.status === "scheduled" && (
                                                        <RenameIcon
                                                            className="w-4 h-4 ml-auto cursor-pointer"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRenameClick(activity.scheduledEmailId);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {editActivityId === activity.scheduledEmailId ? (
                                                <div className="flex flex-col gap-2">
                                                    <textarea
                                                        value={activity.body}
                                                        onChange={(e) =>
                                                            handleInputChange(activity.scheduledEmailId, "body", e.target.value)
                                                        }
                                                        className="text-sm border p-1 bg-transparent outline-none resize-none"
                                                        onClick={(e) => e.stopPropagation()}
                                                        rows={5}
                                                    />
                                                    <p className="p-reserved mt-3 font-semibold">Set Scheduled Date and Time</p>
                                                    <input
                                                        type="datetime-local"
                                                        value={datetime}
                                                        onChange={(e) => setDatetime(e.target.value)}
                                                        min={getCurrentDateTimeForInput()}
                                                        className="campaign-card-input !border-dgray-300 !bg-dgray-100 mt-1 !w-[200px]"
                                                    />
                                                    <div className="flex gap-2 mt-3">
                                                        <button
                                                            className="px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
                                                            onClick={() => handleSaveClick(activity)}
                                                            disabled={loading.saving}
                                                        >
                                                            Save
                                                        </button>
                                                        <button
                                                            className="px-2 py-1 bg-gray-300 hover:bg-gray-400 text-black rounded"
                                                            onClick={handleCancelClick}
                                                            disabled={loading.saving}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : activity.status === "sent" ? (
                                                <p className="text-sm p-reserved">{ReactHtmlParser(activity.body.content)}</p>
                                            ) : activity.status === "scheduled" ? (
                                               <p className="text-sm p-reserved">{formatText(activity.body)}</p>
                                            ) : (
                                                <p className="text-sm p-reserved">{ReactHtmlParser(activity.body.content)}</p>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
