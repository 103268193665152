import LandingLayout from "@/components/LandingLayout";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Typewriter from "typewriter-effect";

import AI_Email_Warmup from "@/assets/image/AI_Email_Warmup.svg";
import AI_Engagement from "@/assets/image/AI_Engagement.svg";
import { ReactComponent as HighLight } from "@/assets/image/base/highlight.svg";
import mainArrowSvg from "@/assets/image/base/mainArrow.svg";
import card1 from "@/assets/image/static-landing-page/card-1.png";
import card2 from "@/assets/image/static-landing-page/card-2.png";
import card3 from "@/assets/image/static-landing-page/card-3.png";
import g2Svg from "@/assets/image/icons/g2.svg";
import googleSvg from "@/assets/image/icons/google.svg";
import Kompass_Search from "@/assets/image/Kompass_Search.svg";
import LandingImgTable from "@/assets/image/LandingImgTable.svg";
import Pipeline from "@/assets/image/Pipeline.svg";
import Website_Intent from "@/assets/image/Website_Intent.svg";
import { Rating } from "@material-tailwind/react";

const ColorChangingButton = () => {
    return (
        <Link to="/signup">
            <button
                id="changeButton"
                className="relative font-OutfitMedium flex items-center rounded-md bg-black p-3 2xl:p-4 pl-7 !pr-[3rem] text-white "
            >
                <p>Try AI Search</p>
                <div id="firstIcon" className="flex items-center justify-center w-5 h-5">
                    <img src={mainArrowSvg} alt="RightArrowIcon" width={20} height={20} />
                </div>
                <div id="secondIcon" className="flex items-center justify-center w-5 h-5">
                    <img src={mainArrowSvg} alt="RightArrowIcon" width={20} height={20} />
                </div>
            </button>
        </Link>
    );
};

export default function LandingStatic() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    return (
        <div id="main">
            <LandingLayout>
                <div className="w-full overflow-x-hidden antialiased text-gray-600">
                    <Box className="flex items-center justify-center sm:justify-start">
                        <Box className="relative flex flex-col items-center justify-center w-full gap-16 pt-20 pb-20 px-6 mx-auto my-0 kompass-animation 2xl:pt-16 2xl:pb-32 sm:flex-row sm:gap-4 md:gap-8 lg:gap-12 xl:gap-32 2xl:gap-40">
                            <Box className="relative flex flex-col items-center gap-0.5">
                                <Box className="mt-4 xl:mt-6 h-fit relative">
                                    <HighLight className="absolute w-[18px] h-[18px] md:w-[24px] md:h-[24px] top-[-10px] left-[-10px] md:-top-2 md:-left-2 2xl:top-0 2xl:left-0" />
                                    <h1 className="whitespace-nowrap text-center text-[23px] sm:text-[36px] md:text-[50px] xl:text-[60px] 2xl:text-8xl leading-[1.1] font-bold text-stone-950">
                                        Automate pipeline generation
                                        <span className="flex whitespace-nowrap justify-center gap-3">
                                            and focus on <span className="text-[#4873FA] max-lg:block"> selling.</span>
                                        </span>
                                    </h1>
                                </Box>
                                <Box className="mt-6 xl:mt-8 h-fit">
                                    <p className="text-[12px] lg:text-[14px] text-center xl:text-[16px] 2xl:text-2xl max-w-[320px] sm:max-w-[420px] md:max-w-[660px] xl:max-w-[860px] 2xl:max-w-[1350px] font-normal font-Outfit leading-[1.4] text-neutral-500">
                                        Join sales and marketing teams using KompassAI to expand their businesses globally. Get started by{" "}
                                        <br className="hidden md:block" />
                                        tapping into the world’s largest pool of 800M+ contacts. Next, use AI to engage with and convert
                                        clients at scale.
                                    </p>
                                </Box>
                                <Box className="relative !bg-white w-full mt-8 lg:mt-12 2xl:mt-16 flex max-w-[306px] xs:max-w-[380px] lg:max-w-[440px] xl:max-w-[440px] items-center justify-between rounded-md border border-stone-950">
                                    <Box className="ml-6 flex w-36 lg:w-[200px] flex-1 items-center justify-start">
                                        <Typewriter
                                            options={{
                                                strings: ["Elon Musk", "Mark Zuckerberg", "Warren Buffett", "Jay Z", "Stephen Curry"],
                                                autoStart: true,
                                                loop: true,
                                                skipAddStyles: true,
                                                wrapperClassName: "font-Outfit text-stone-350",
                                            }}
                                        />
                                    </Box>
                                    <Box className="p-1.5">
                                        <ColorChangingButton />
                                    </Box>
                                </Box>
                                <Box className="mt-10 xl:mt-8 h-fit">
                                    <Box className="flex flex-col items-center gap-6 lg:gap-12 md:flex-row">
                                        <Box className="flex items-center justify-center gap-2">
                                            <img src={googleSvg} alt="GoogleIcon" width={40} height={40} />
                                            <Box className="flex flex-col justify-start gap-0.5">
                                                <p className="text-black/50 text-[13px] capitalize">4.9 Stars</p>
                                                <Rating
                                                    value={5}
                                                    readonly
                                                    className="text-[#FCC153]"
                                                    unratedColor="yellow"
                                                    ratedColor="yellow"
                                                />
                                            </Box>
                                        </Box>

                                        <div className="border-l h-[27px] border-black opacity-20 hidden md:block"></div>
                                        <div className="border-t w-full h-[1px] border-black opacity-20 md:hidden block"></div>

                                        <Box className="flex items-center justify-center gap-2">
                                            <img src={g2Svg} alt="G2Icon" width={40} height={40} />
                                            <Box className="flex flex-col justify-start gap-0.5">
                                                <p className="text-black/50 text-[13px] capitalize">5 Stars</p>
                                                <Rating
                                                    value={5}
                                                    readonly
                                                    className="text-[#FCC153]"
                                                    unratedColor="yellow"
                                                    ratedColor="yellow"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="flex items-center justify-center px-6">
                        <img src={LandingImgTable} alt="LandingStaticSvg" />
                    </Box>

                    <div className="flex flex-col items-center justify-start mt-36 w-full p-5 py-14 md:p-10 lg:p-20 md:pt-20 h-auto bg-zinc-950">
                        <p className="text-gray-100 text-center font-outfit max-w-[1450px] text-[24px] md:text-[55px] font-semibold leading-[110%]">
                            The legacy pipeline generation model is broken
                        </p>
                        <p className="text-gray-100 opacity-70 text-center mt-6 max-w-[1250px] font-outfit md:text-[20px] mx-2 md:mx-10 lg:mx-20 font-normal leading-[110%]">
                            A prospect’s journey from a cold lead to demo booking across manual workflows and multiple point systems creates
                            inefficiencies within your sales organization, reducing productivity and increasing customer acquisition costs
                        </p>
                        <img src={Pipeline} alt="LandingStaticImg1" className="mt-10 md:mt-20 mb-4 w-max" />
                    </div>
                    <div className="md:py-[80px] py-16 md:px-20 px-5">
                        <div className="flex justify-start items-center flex-col">
                            <p className="text-stone-950 text-center font-outfit text-[24px] md:text-[55px] font-semibold leading-[110%]">
                                KompassAI is the all-in-one agentic AI platform
                            </p>
                            <p className="text-black text-center font-outfit md:text-[20px] mx-5 md:mx-20 pt-6 font-normal leading-[1.2] opacity-70  max-w-[1250px]">
                                Join companies using KompassAI to empower sales and marketing professionals with efficient AI-powered
                                workflows to expand their businesses across local and international markets
                            </p>
                        </div>
                        <div id="intent-signals" className="flex flex-col xl:flex-row justify-between xl:items-center pt-20">
                            <div className="flex flex-col justify-start items-start max-w-[1250px]">
                                <div className="py-2 px-3 rounded-md bg-[#D6E0FF]">
                                    <p className="text-[#454545] font-outfit text-sm whitespace-nowrap md:text-[22px] font-normal leading-[1.2]">
                                        Leverage Intent Signals
                                    </p>
                                </div>
                                <p className="text-zinc-950 font-outfit text-lg md:text-[32px] font-semibold leading-[1.2] pt-6">
                                    Deanonymize website traffic to determine hidden opportunities
                                </p>
                                <div className="pt-6">
                                    <ul className="list-disc md:text-md marker:text-[#454545] ml-5 md:ml-8">
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4]">
                                            Identify 25% to 30% of the people and companies that visit your website
                                        </li>
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4] mt-3">
                                            Automated workflow to create targeted lists for sales and marketing campaigns based on website
                                            traffic
                                        </li>
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4] mt-3">
                                            Enhances conversion rates by focusing on clients with intent to purchase product
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex xl:block justify-center">
                                <img
                                    src={Website_Intent}
                                    alt="public_view_1"
                                    className="!max-w-[800px] xl:ml-6 h-full mt-10 xl:mt-0 w-full xl:w-[750px] xl:h-[525px]"
                                />
                            </div>
                        </div>
                    </div>
                    <div id="prospect" className="md:py-[80px] py-16 md:px-20 px-5">
                        <div className="flex flex-col-reverse xl:flex-row justify-between xl:items-center">
                            <div className="flex xl:block justify-center">
                                <img
                                    src={Kompass_Search}
                                    alt="public_view_1"
                                    className="!max-w-[800px] xl:pr-10 xl:mr-6 h-full mt-10 xl:mt-0 w-full xl:w-[750px] xl:h-[525px]"
                                />
                            </div>
                            <div className="flex flex-col justify-start items-start max-w-[1250px]">
                                <div className="py-2 px-3 rounded-md bg-[#D6E0FF]">
                                    <p className="text-[#454545] font-outfit text-sm whitespace-nowrap md:text-[22px] font-normal leading-[1.2]">
                                        Create Targeted ICP Lists
                                    </p>
                                </div>
                                <p className="text-zinc-950 font-outfit text-lg md:text-[32px] font-semibold leading-[1.2] pt-6">
                                    Quickly identify and target the most relevant prospects
                                </p>
                                <div className="pt-6">
                                    <ul className="list-disc md:text-md marker:text-[#454545] ml-5 md:ml-8">
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4]">
                                            Tap into a pool of over 800 million B2B contacts to provide you global coverage
                                        </li>
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4] mt-3">
                                            Laser-focused filtering with 40+ different criterion including: firmographics, technographics,
                                            funding, jobs, among others
                                        </li>
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4] mt-3">
                                            Real-time verified emails and phone numbers with an industry leading 90%+ fill-rate to prospect
                                            with confidence
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="engage" className="md:py-[80px] py-16 md:px-20 px-5">
                        <div className="flex flex-col xl:flex-row justify-between xl:items-center">
                            <div className="flex flex-col justify-start items-start max-w-[1250px]">
                                <div className="py-2 px-3 rounded-md bg-[#D6E0FF]">
                                    <p className="text-[#454545] font-outfit text-sm whitespace-nowrap md:text-[22px] font-normal leading-[1.2]">
                                        Email Engagement on Autopilot
                                    </p>
                                </div>
                                <p className="text-zinc-950 font-outfit text-lg md:text-[32px] font-semibold leading-[1.2] pt-6">
                                    AI-enhanced messaging trained by the top 1% of sales performers
                                </p>
                                <div className="pt-6">
                                    <ul className="list-disc  md:text-md marker:text-[#454545] ml-5 md:ml-8">
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4]">
                                            Craft hyper-personalized messages at scale for each company and decision maker, driving up-to
                                            300% higher demo conversion rates
                                        </li>
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4] mt-3">
                                            Sales agents are available and manage your inbox 24/7, enabling you to start engaging untapped
                                            market segments and booking meetings completely autonomously
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex xl:block justify-center">
                                <img
                                    src={AI_Engagement}
                                    alt="public_view_1"
                                    className="!max-w-[800px] xl:pr-10 xl:ml-6 h-full mt-10 xl:mt-0 w-full xl:w-[750px] xl:h-[525px]"
                                />
                            </div>
                        </div>
                    </div>
                    <div id="email-deliverability" className="md:py-[80px] py-16 md:px-20 px-5">
                        <div className="flex flex-col-reverse xl:flex-row justify-between xl:items-center">
                            <div className="flex xl:block justify-center">
                                <img
                                    src={AI_Email_Warmup}
                                    alt="public_view_1"
                                    className="!max-w-[800px] xl:pr-10 xl:mr-6 h-full mt-10 xl:mt-0 w-full xl:w-[750px] xl:h-[525px]"
                                />
                            </div>
                            <div className="flex flex-col justify-start items-start max-w-[1250px]">
                                <div className="py-2 px-3 rounded-md bg-[#D6E0FF]">
                                    <p className="text-[#454545] font-outfit text-sm whitespace-nowrap md:text-[22px] font-normal leading-[1.2]">
                                        Managed Email Deliverability
                                    </p>
                                </div>
                                <p className="text-zinc-950 font-outfit text-lg md:text-[32px] font-semibold leading-[1.2] pt-6">
                                    AI-driven processes to simulate genuine interactions, monitor sender reputation, and provide insights
                                </p>
                                <div className="pt-6">
                                    <ul className="list-disc md:text-md marker:text-[#454545] ml-5 md:ml-8">
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4]">
                                            Our fully automated network tool helps ensure your emails stay out of spam and boosts your
                                            sending reputation through positive interactions
                                        </li>
                                        <li className="text-[#454545] font-outfit font-normal leading-[1.4] mt-2">
                                            Manage your domain health across key parameters including: DMARC, SPF, Domain Age, Spam Rate
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:pt-[40px] md:pb-[80px] py-16 md:px-[80px] px-5">
                        <div className="flex flex-col items-center justify-center">
                            <p className="text-black text-center font-outfit text-[24px] md:text-[55px] font-semibold leading-[36px] md:leading-[62px]">
                                Kompass AI empowers your entire GTM team
                            </p>
                            <p className="text-stone-950 text-center font-outfit mt-5 text-[16px] sm:text-[20px] md:text-[28px] font-normal leading-normal">
                                Stop prospecting, start connecting
                            </p>
                        </div>
                        <div className="flex flex-wrap gap-10 justify-center text-[#454545] mt-12 md:mt-16">
                            <div className="border border-[#B0B0B0] p-6 rounded-[16px] max-w-[290px] 3xl:max-w-[390px] basis-full">
                                <img src={card1} alt="card1" className="" />
                                <h2 className="font-semibold mt-3 3xl:text-md">Revenue Operations</h2>
                                <p className="mt-3 3xl:text-md">
                                    Streamline outbound operations and enhance reps' efficiency with a top-tier AI-driven solution
                                </p>
                            </div>
                            <div className="border border-[#B0B0B0] p-6 rounded-[16px] max-w-[290px] 3xl:max-w-[390px] basis-full">
                                <img src={card2} alt="card1" className="" />
                                <h2 className="font-semibold mt-3 3xl:text-md">Marketing & Growth</h2>
                                <p className="mt-3 3xl:text-md">
                                    Grow pipeline efficiently and lower CAC with a 24/7 autonomous engagement engine
                                </p>
                            </div>
                            <div className="border border-[#B0B0B0] p-6 rounded-[16px] max-w-[290px] 3xl:max-w-[390px] basis-full">
                                <img src={card3} alt="card1" className="" />
                                <h2 className="font-semibold mt-3 3xl:text-md">Revenue Operations</h2>
                                <p className="mt-3 3xl:text-md">
                                    Achieve unparalleled efficiency by generating opportunities at scale with the speed of AI
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-black md:py-[80px] md:px-20 py-16 px-5 gap-20 flex flex-col justify-center items-center">
                        <p className="text-white text-center font-outfit text-[24px] md:text-[48px] font-semibold leading-normal">
                            Increase revenue through scaling personalized outreach
                        </p>
                        <div className="flex flex-col lg:flex-row justify-start items-center gap-[120px]">
                            <div>
                                <p className="text-white text-center font-outfit text-8xl  font-semibold leading-normal">20%</p>
                                <p className="text-white text-center font-outfit text-[24px] font-semibold leading-normal">
                                    more accurate leads
                                </p>
                            </div>
                            <div>
                                <p className="text-white text-center font-outfit text-8xl font-semibold leading-normal">60%</p>
                                <p className="text-white text-center font-outfit text-[24px] font-semibold leading-normal">
                                    higher open rate
                                </p>
                            </div>
                            <div>
                                <p className="text-white text-center font-outfit text-8xl font-semibold leading-normal">3%</p>
                                <p className="text-white text-center font-outfit text-[24px] font-semibold leading-normal">
                                    lead-to-demo CR
                                </p>
                            </div>
                            <div>
                                <p className="text-white text-center font-outfit text-8xl font-semibold leading-normal">40%</p>
                                <p className="text-white text-center font-outfit text-[24px] font-semibold leading-normal">
                                    higher reply rate
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:py-[80px] py-16 md:px-20 px-5 flex flex-col gap-12 xl:gap-20">
                    <div className="flex flex-col mx-auto items-center max-w-[1250px]">
                        <p className="text-black text-center font-outfit text-[30px] md:text-[64px] font-semibold leading-normal leading-[36px] md:leading-[70px]">
                            Why Choose KompassAI
                        </p>
                        <div class="opacity-70 text-center mt-5 text-black text-xl md:text-2xl max-w-[800px] font-normal font-outfit leading-[28.80px]">
                            We’re building the next generation of outbound software: all-in-one and AI first. The days of a multi-product
                            stack centered around outreach or salesloft are over
                        </div>
                        <div className="pt-[40px]">
                            <NavLink to="/demo" className="text-white bg-black border border-black kompassai-get-started-button">
                                Talk To Sales
                            </NavLink>
                        </div>
                    </div>

                    <div className="flex flex-col xl:flex-row justify-start gap-4 xl:items-center">
                        <div className="flex flex-col justify-start mt-5 xl:mt-0">
                            <p className="text-stone-950 text-[14px] md:text-[18px] xl:mb-6 font-semibold font-outfit leading-[28.80px]">
                                Seamless Setup
                            </p>
                            <p className="text-stone-950 text-[16px] md:text-[20px] font-normal font-outfit leading-[28.80px]">
                                Our simplified user experience enables users to start generating pipeline in less than two minutes
                            </p>
                        </div>
                        <div class="w-[0px] h-[166px] hidden xl:block origin-top-left border border-zinc-400 mx-6"></div>
                        <div className="flex flex-col justify-start mt-5 xl:mt-0">
                            <p className="text-stone-950 text-[14px] md:text-[18px] xl:mb-6 font-semibold font-outfit leading-[28.80px]">
                                Pipeline on autopilot
                            </p>
                            <p className="text-stone-950 text-[16px] md:text-[20px] font-normal font-outfit leading-[28.80px]">
                                Fully automated outreach from the first message all the way up-to booking demos in your calendar
                            </p>
                        </div>
                        <div class="w-[0px] h-[166px] hidden xl:block origin-top-left border border-zinc-400 mx-6"></div>
                        <div className="flex flex-col justify-start mt-5 xl:mt-0">
                            <p className="text-stone-950 text-[14px] md:text-[18px] xl:mb-6 font-semibold font-outfit leading-[28.80px]">
                                AI that learns from you
                            </p>
                            <p className="text-stone-950 text-[16px] md:text-[20px] font-normal font-outfit leading-[28.80px]">
                                Our system is designed to adapt and improve your engagement based on your interactions with prospects
                            </p>
                        </div>
                        <div class="w-[0px] h-[166px] hidden xl:block origin-top-left border border-zinc-400 mx-6"></div>
                        <div className="flex flex-col justify-start mt-5 xl:mt-0">
                            <p className="text-stone-950 text-[14px] md:text-[18px] xl:mb-6 font-semibold font-outfit leading-[28.80px]">
                                All-in-one platform
                            </p>
                            <p className="text-stone-950 text-[16px] md:text-[20px] font-normal font-outfit leading-[28.80px]">
                                Drive improved revenue outcomes while saving hundreds of thousands annually
                            </p>
                        </div>
                    </div>
                </div>
            </LandingLayout>
        </div>
    );
}
