import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import GoogleIcon from "@/assets/image/icons/google.svg";
import OutLookIcon from "@/assets/image/icons/outlook.svg";
import AlertDialog from "@/components/common/AlertDialog";
import BackdropLoader from "@/components/common/BackdropLoader";
import AlertsIcon from "@/components/icons/Alerts";
import useAiEngagement from "@/hooks/useAIEngagement";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import MailBoxHeader from "./MailBoxHeader";
import UpdateCapacityDailog from "./UpdateCapacityDailog";

const initialState = {
    emailList: [],
    loading: false,
    selectedEmailObj: {},
    confimationType: "updateStatus",
    isupdateCapacityModal: false,
};
const MailBox = () => {
    const [state, setState] = useState(initialState);

    const [showConfimationModal, setShowConfimationModal] = useState(false);

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const { emailList, loading, selectedEmailObj, confimationType, isupdateCapacityModal } = state;

    const dense = false;
    const { getEmailsData, connectWithThirdPartyService, fetchDataFromCallBack, updateMailBoxStatus, removeEmailConnection } =
        useAiEngagement();

    const fetchEmailList = async () => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const listData = await getEmailsData();
            if (listData?.data || false) {
                setState((prevState) => {
                    let updatedListData = listData?.data;

                    return {
                        ...prevState,
                        emailList: updatedListData || [],
                    };
                });
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const getConnectedWithService = async (type) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await connectWithThirdPartyService({ authType: type });
            console.log("response", response);
            if ((response?.data || false) && response?.data?.authUrl) {
                const a = document.createElement("a");
                a.href = response?.data?.authUrl || "";
                a.target = "_blank";
                a.click();
                a.remove();
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };
    const sendCallBackData = async (type, code, scope) => {
        try {
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await fetchDataFromCallBack({
                url: `${type === "gmail" ? "/mail/gmail/oauth2callback" : "/mail/outlook/oauth2callback"}?code=${code}&scope=${scope}`,
            });
            if (response?.data?.statusCode === 200) {
                const url = window?.location?.pathname;
                navigate(url, { replace: true });
                window?.location?.reload();
            } else {
                toast.error(response?.data?.message, {
                    theme: "colored",
                });
            }
            // const url = window?.location?.pathname;
            // navigate(url, { replace: true });
            // window?.location?.reload();
        } catch (error) {
            console.log("-error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const statusUpdate = async () => {
        try {
            const { isActive = false, account: type = "gamil", emailAddress } = selectedEmailObj || {};
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await updateMailBoxStatus({
                url: `/mail/gmail/active?is_gmail=${type === "gmail" ? "true" : "false"}&isActive=${!isActive}&emailAddress=${emailAddress}`,
            });
            if (response?.status === true) {
                setShowConfimationModal(false);
                toast.success(response?.message, { theme: "colored" });
                fetchEmailList();
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    const removeConnection = async () => {
        try {
            const { account: type = "gamil", emailAddress } = selectedEmailObj || {};
            setState((prevState) => ({ ...prevState, loading: true }));
            const response = await removeEmailConnection({
                url: `/mail/${type === "gmail" ? "gmail" : "outlook"}/disconnect?emailAddress=${emailAddress}`,
            });
            if (response?.status === true) {
                setShowConfimationModal(false);
                toast.success(response?.message, { theme: "colored" });
                fetchEmailList();
            } else {
                toast.error(response?.message, {
                    theme: "colored",
                });
            }
        } catch (error) {
            console.log("-error", error);
        } finally {
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    };

    useEffect(() => {
        if (searchParams) {
            if (searchParams?.size > 0) {
                sendCallBackData(searchParams.get("url") ? "gmail" : "outlook", searchParams.get("code"), searchParams.get("scope"));
            } else {
                fetchEmailList();
            }
        }
    }, [searchParams]);

    return (
        <>
            {loading && <BackdropLoader clsName="!z-[9999]" active={true}></BackdropLoader>}
            <AlertDialog
                open={showConfimationModal}
                message={
                    confimationType === "updateStatus"
                        ? "Are You sure you want to update status?"
                        : "Are You sure you want to disconnect account?"
                }
                handleClose={() => setShowConfimationModal(false)}
                handleOK={() => {
                    if (confimationType === "updateStatus") {
                        statusUpdate();
                    } else if (confimationType === "delete_Account") {
                        removeConnection();
                    }
                }}
            />
            <UpdateCapacityDailog
                open={isupdateCapacityModal}
                onClose={(type) => {
                    setState((prevState) => ({
                        ...prevState,
                        isupdateCapacityModal: false,
                    }));
                    if (type == true) {
                        fetchEmailList();
                    }
                }}
                dataObj={selectedEmailObj}
            />
            <Box sx={{ width: "100%", height: "100%", marginTop: "10px" }}>
                <TableContainer className="tab_conatiner_ma">
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{
                            maxHeight: 440,
                            borderCollapse: "separate",
                            border: "none",
                        }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                    >
                        <MailBoxHeader />
                        <TableBody
                            className="relative"
                            sx={{
                                "& .MuiTableCell-sizeMedium": {
                                    // padding: "5px 8px",
                                    fontFamily: "Outfit",
                                    // height: "fit-content",
                                    fontSize: 14,
                                },
                            }}
                        >
                            {emailList.length > 0 ? (
                                emailList.map((row, index) => (
                                    <TableRow className="cursor-pointer" key={index}>
                                        <TableCell align="left">
                                            <div className="flex gap-x-2">
                                                <img
                                                    className="h-[20px] w-[20px]"
                                                    src={row?.account === "gmail" ? GoogleIcon : OutLookIcon}
                                                    alt="google icon"
                                                />
                                                {row?.emailAddress || "-"}
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">
                                            {row?.dailyCapacity ? (
                                                <span
                                                    className="mail_count"
                                                    onClick={() => {
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            isupdateCapacityModal: true,
                                                            selectedEmailObj: row,
                                                        }));
                                                    }}
                                                >
                                                    {row?.dailyCapacity ?? ""}
                                                </span>
                                            ) : (
                                                "-"
                                            )}
                                        </TableCell>
                                        <TableCell align="center !flex !justify-center">
                                            <span className="flex gap-x-2 mail_status">
                                                Urgent <AlertsIcon />
                                            </span>
                                        </TableCell>
                                        <TableCell align="left" onClick={() => navigate(`/email-health/${row?.emailAddress}`)}>
                                            <div className="m_box_health">
                                                <span className="active"></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Switch
                                                color="info"
                                                checked={row?.isActive || false}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        selectedEmailObj: row,
                                                        confimationType: "updateStatus",
                                                    }));
                                                    setShowConfimationModal(true);
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell
                                            align="right"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    selectedEmailObj: row,
                                                    confimationType: "delete_Account",
                                                }));
                                                setShowConfimationModal(true);
                                            }}
                                        >
                                            <button className="rounded-[8px] py-[4px] px-[12px] border bg-white font-Outfit leading-[1.4] text-[#E5322B] focus:text-[#fff] focus:bg-[#E5322B] border-[#E5322B] focus:border-[#E5322B] hover:text-[#fff] hover:bg-[#E5322B] hover:border-[#E5322B] transition-colors">
                                                Delete
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <div className="no-emails-message">No emails available</div>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* {!loading &&
        <div className='bg-[#E8E7E7] rounded-[8px] py-[10px] px-[20px] w-fit  mt-4'>
          <span className="w-full">Connect Your Google Or Outlook Account</span>
          <div className="flex justify-start items-center md:flex-row flex-col gap-x-3">
            <Button
              className="google-button !w-auto items-center "
              onClick={() => getConnectedWithService("gmail")}
            >
              <img className="h-[20px] w-[20px]" src={GoogleIcon} alt="GoogleIcon" />
              <div className='text-[#4873FA]'>connect via Google</div>
            </Button>
            <span>OR</span>
            <Button
              className="google-button !w-auto items-center"
              onClick={() => getConnectedWithService("outlook")}
            >
              <img className="h-[20px] w-[20px]" src={OutLookIcon} alt="GoogleIcon" />
              <div className='text-[#0078D4]'>connect via outlook</div>
            </Button>
          </div>

        </div>} */}
        </>
    );
};

export default MailBox;
