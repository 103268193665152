import * as React from "react";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { getShortName, indexString } from "@/utils/common";
import Chip from "@mui/material/Chip";

import BusinessIcon from "@mui/icons-material/Business";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RiskIcon from "@mui/icons-material/AssistantPhotoOutlined";
import WarningOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { ReactComponent as MailGrayIcon } from "@/assets/image/search/mail2.svg";
import { ReactComponent as PhoneGrayIcon } from "@/assets/image/search/call2.svg";
import { ReactComponent as CopyGrayIcon } from "@/assets/image/search/copy2.svg";
import { toast } from "react-toastify";

export default function ContactDetailsModal({ open, setOpen, rowData }) {
    const [imageLoaded, setImageLoaded] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast.success("Copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
                toast.error("Failed to copy to clipboard!");
            });
    };

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <div className="min-w-[700px]">
                <div className="flex items-center justify-between p-5 border-b border-stone-250">
                    <h2 className="text-lg font-semibold">Details</h2>
                    <div onClick={toggleDrawer(false)}>
                        <CloseIcon className="text-dgray-500 cursor-pointer hover:text-dgray-600" />
                    </div>
                </div>
                <div className=" border border-stone-250 m-5 rounded-lg">
                    <p className="bg-[#F6F6F6] rounded-t-lg p-4">Contact Details</p>
                    <div className="p-5 border-b border-stone-250">
                        <div className="flex flex-row items-center gap-2 ">
                            <div className="flex">
                                {rowData?.profilePictureUrl ? (
                                    <img src={rowData?.profilePictureUrl} width={40} height={40} alt={rowData?.profilePictureUrl} />
                                ) : (
                                    <div
                                        className={
                                            "!text-[12px] font-OutfitMedium flex h-[40px] w-[40px] items-center p-1 justify-center rounded-full " +
                                            [
                                                " bg-accents-yellow text-black",
                                                " bg-accents-purple text-black",
                                                " bg-accents-green text-black",
                                                " bg-accents-blue text-black",
                                                " bg-accents-orange text-black",
                                            ][indexString(rowData?.full_name, 5)]
                                        }
                                    >
                                        {getShortName(rowData?.full_name)}
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col w-full">
                                <p className="capitalize font-medium ">{rowData?.full_name}</p>
                                <p className="capitalize text-sm text-dgray-600">{rowData?.job_title}</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-5 mt-5">
                            <div className="bg-[#F6F6F6] rounded-lg py-2 px-4 min-w-[150px]">
                                <p className="text-dgray-500 text-sm">Education</p>
                                <h2 className="font-medium mt-1 text-[17px] capitalize">Dummy Data</h2>
                            </div>
                            {rowData?.job_title_role && <div className="bg-[#F6F6F6] rounded-lg py-2 px-4 min-w-[150px]">
                                <p className="text-dgray-500 text-sm">Department</p>
                                <h2 className="font-medium mt-1 text-[17px] capitalize">{rowData?.job_title_role}</h2>
                            </div>}
                            {rowData?.job_title_levels?.length > 0 && <div className="bg-[#F6F6F6] rounded-lg py-2 px-4 min-w-[150px]">
                                <p className="text-dgray-500 text-sm">Level</p>
                                <h2 className="font-medium mt-1 text-[17px] capitalize">{rowData?.job_title_levels?.join(", ")}</h2>
                            </div>}
                            {rowData?.location && <div className="bg-[#F6F6F6] rounded-lg py-2 px-4 min-w-[150px]">
                                <p className="text-dgray-500 text-sm">Country</p>
                                <h2 className="font-medium mt-1 text-[17px] capitalize">
                                    {rowData?.location.split(",")[rowData?.location?.split(",")?.length - 1] || ""}
                                </h2>
                            </div>}
                        </div>
                    </div>
                    <div className="m-5 flex gap-5">
                        <div className="w-[70%]">
                            <p className="mt-5 font-semibold text-start">Contact Emails: </p>
                            {rowData?.enrichedEmails?.length > 0 &&
                                rowData?.enrichedEmails.map((item, key) => {
                                    return (
                                        <div key={key} className="grid grid-cols-12 gap-5 items-center text-gray-950 mt-2.5">
                                            <div className="flex items-center gap-2 col-span-9">
                                                <MailGrayIcon className="w-[16px] h-[16px]" />
                                                {item.email}
                                                <p className="ml-1 font-Outfit opacity-80">
                                                    {item.type === "Valid" ? (
                                                        <Chip
                                                            icon={<CheckCircleIcon className="!text-green-700 !text-[14px]" />}
                                                            label="Valid"
                                                            size="small"
                                                            className="!bg-green-100 !text-green-700 !px-1 !rounded-md"
                                                            style={{ fontSize: 10, fontFamily: "Outfit" }}
                                                        />
                                                    ) : null}
                                                    {item.type === "Personal" ? (
                                                        <Chip
                                                            icon={<CheckCircleIcon className="!text-blue-700 !text-[14px]" />}
                                                            label="Personal"
                                                            size="small"
                                                            className="!bg-blue-100 !text-blue-700 !rounded-md"
                                                            style={{ fontSize: 10, fontFamily: "Outfit" }}
                                                        />
                                                    ) : null}
                                                    {item.type === "Risky" ? (
                                                        <Chip
                                                            icon={<RiskIcon className="!text-red-700 !text-[14px]" />}
                                                            label="High Risk"
                                                            size="small"
                                                            className="!bg-red-100 !text-red-700 !rounded-md"
                                                            style={{ fontSize: 10, fontFamily: "Outfit" }}
                                                        />
                                                    ) : null}
                                                    {item.type === "Unknown" ? (
                                                        <Chip
                                                            icon={<WarningOutlinedIcon className="!text-yellow-700 !font-[14px]" />}
                                                            label="Suspicious"
                                                            size="small"
                                                            className="!bg-yellow-100 !text-yellow-700 !rounded-md"
                                                            style={{ fontSize: 10, fontFamily: "Outfit" }}
                                                        />
                                                    ) : null}
                                                    {item.type === "Do not email" ? (
                                                        <Chip
                                                            icon={<RiskIcon className="!text-red-700 !font-[14px]" />}
                                                            label="Do Not Email"
                                                            size="small"
                                                            className="!bg-red-100 !text-red-700 !rounded-md"
                                                            style={{ fontSize: 10, fontFamily: "Outfit" }}
                                                        />
                                                    ) : null}
                                                </p>
                                            </div>
                                            <div className="col-span-3">
                                                <CopyGrayIcon
                                                    className="w-[16px] h-[16px]  cursor-pointer"
                                                    onClick={() => copyToClipboard(item.email)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            {rowData?.isEmailFound === false && <div className="text-sm text-gray-950 mt-1">Email not available</div>}
                            <p className="mt-5 font-semibold text-start">Contact Phones: </p>
                            {rowData?.enrichedPhones?.length > 0 &&
                                rowData.enrichedPhones.map((item, key) => {
                                    return (
                                        <div key={key} className="grid grid-cols-12 gap-5 items-center text-gray-950 mt-2.5">
                                            <div className="flex items-center gap-2 col-span-9">
                                                <PhoneGrayIcon className="w-[16px] h-[16px]" />
                                                {item.phone_number}
                                                <p className="font-Outfit opacity-80">
                                                    {item.status === "valid" ? (
                                                        <Chip
                                                            icon={<BusinessIcon className="!text-green-700 !text-[14px] " />}
                                                            label="Direct dial"
                                                            size="small"
                                                            className="!bg-green-100 !text-green-700 !px-1 !rounded-md"
                                                            style={{ fontSize: 10, fontFamily: "Outfit" }}
                                                        />
                                                    ) : null}
                                                    {item.status === "hq" ? (
                                                        <Chip
                                                            icon={<BusinessIcon className="!text-blue-700 !text-[14px]" />}
                                                            label="Headquarters"
                                                            size="small"
                                                            className="!bg-blue-100 !text-blue-700 !px-1 !rounded-md"
                                                            style={{ fontSize: 10, fontFamily: "Outfit" }}
                                                        />
                                                    ) : null}
                                                </p>
                                            </div>
                                            <div className="col-span-3">
                                                <CopyGrayIcon
                                                    className="w-[16px] h-[16px] cursor-pointer"
                                                    onClick={() => copyToClipboard(item.phone_number)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            {rowData?.isPhoneFound === false && <div className="text-sm text-gray-950 mt-1">Phone not available</div>}
                        </div>
                        <div className="w-[30%]">
                            <p className="mt-5 font-semibold text-start">Work History</p>
                            <div className="flex h-[40px] w-[40px] shrink-0 mt-4">
                                <img
                                    src={`https://logo.clearbit.com/${rowData?.job_company_website}`}
                                    alt="Logo"
                                    className="relative object-contain object-center"
                                    onLoad={() => setImageLoaded(true)}
                                    onError={() => setImageLoaded(false)}
                                    style={{
                                        display: imageLoaded ? "block" : "none",
                                    }}
                                />
                                {imageLoaded === false && (
                                    <div
                                        className={
                                            "!text-[10px] font-OutfitMedium flex h-[30px] w-[30px] p-1 items-center justify-center rounded-full" +
                                            [
                                                " bg-accents-yellow text-black",
                                                " bg-accents-purple text-black",
                                                " bg-accents-green text-black",
                                                " bg-accents-blue text-black",
                                                " bg-accents-orange text-black",
                                            ][indexString(rowData?.job_company_name, 5)]
                                        }
                                    >
                                        {rowData?.job_company_name ? getShortName(rowData?.job_company_name) : "-"}
                                    </div>
                                )}
                            </div>
                            <p className="capitalize mt-1">{rowData?.job_company_name}</p>
                        </div>
                    </div>
                </div>
                </div>
            </Drawer>
        </div>
    );
}