export const COGNITO_TOKEN = "accessToken";
export const COGNITO_GOOGLE_DEFAULT_PASSWORD = "KompassAIGoogle@00";
export const NoInfoAvailableVal = "N/A";

export const S3_URL = {
    KOMPASS_BULK_TEMPLATE: "https://kompass-ai-templates.s3.amazonaws.com/Bulk_Enrich_Template.zip",
    KOMPASS_EMAIL_TEMPLATE: "https://kompass-ai-templates.s3.amazonaws.com/EmailTemplate.zip",
};

export const STORAGE_KEY = {
    USER_INFO: "userInfo",
    KOMPASS_SEARCH_CONTACT_FILTER: "kscontactFilter",
    KOMPASS_SEARCH_COMPANY_FILTER: "kscompanyFilter",
};

export const KompassString = {
    SomethingWentWrong: "Something went wrong!",
    PasswordChangedSuccessfully: "Password has been successfully! \n Please sign in again",
    WrongOldPassword: "Old password you typed is incorrect",
    SettingChangedSuccessfully: "Setting has been modified! \n Please sign in again",
    BukUploadError: (params) => `Kindly ${params} you'd like to enrich.`,
    Asking4Credits: "Do you want to buy credits?",
};

export const CONTACT_LIST_TYPE = {
    CHROME_EXTENSION: "chromeExt",
    LIVE_SEARCH: "livesearch",
    KOMPASS_SEARCH: "kompasssearch",
};

export const BLOG_TAGS = {
    ALL: "All",
    DATA_ANALYTICS: "Data analytics",
    TRENDS: "Trends",
    MARKETING: "Marketing",
    GUIDE: "Guide",
};

export const FAQ_LIST = [
    {
        question: "What is the KompassAI Chrome Extension?",
        answer: [
            "The KompassAI Chrome Extension is a tool that helps you enrich business profiles on any website. It can find contact information like email addresses, phone numbers, and other details for businesses you come across while browsing the web.",
        ],
    },
    {
        question: "How does it work?",
        answer: [
            "The Chrome extension works by extracting basic contact details like names, titles, companies, and website domains from the webpages you visit. Then, it uses KompassAI's email finder technology to search for the corresponding business email addresses.",
        ],
    },
    {
        question: "How can I be sure the emails are accurate?",
        answer: [
            "KompassAI prioritizes data quality. When the extension finds a business email address, it assigns a confidence level to it. The extension uses a status message on our site to indicate the email's confidence level (e.g., high, medium, low). Emails are also categorized as valid, invalid, or risky.",
        ],
    },
    {
        question: "Is there a limit on how many profiles I can prospect?",
        answer: [
            "There are limits on the number of profiles you can prospect using the Chrome extension within Sales Navigator (around 833). This is because the extension extracts data from adjacent pages to improve accuracy. However, there are no limits for prospecting on LinkedIn pages and KompassSearch.",
        ],
    },
    {
        question: "What is KompassSearch?",
        answer: ["KompassSearch is a proprietary database of over 300 million business records that you can use to create prospect lists."],
    },
    {
        question: "Where can I access the contacts I've purchased?",
        answer: [
            "You can find your purchased contacts in the KompassAI -> List section. There, you can filter and export your contacts to a CSV file or directly to your CRM system.",
        ],
    },
    {
        question: "How can I see my purchase history?",
        answer: [
            "The Default List Page provides a record of your purchases, including the contact's name, company, credits used, and purchase date.",
        ],
    },
    {
        question: "What is AI Search?",
        answer: [
            "AI Search is KompassAI's proprietary technology that gathers data from various sources on the internet and third-party resources (e.g., Identify Verifiers). This provides a highly comprehensive dataset unavailable from any other provider. However, due to the high computational cost and third-party fees, AI Search uses 3 phone credits and 3 email credits per search. The pricing structure is under review and may be adjusted based on demand.",
        ],
    },
    {
        question: "I upgraded a plan. How are the credits calculated?",
        answer: [
            "When you upgrade your plan, you receive credits upfront. The calculation considers the prorated cost of your previous plan, the difference between the credits allocated to your new plan and any credits you've already used, and may be complex due to the current system. KompassAI is transitioning to a simpler credit allocation system in the future.",
        ],
    },
    {
        question: "What is your product roadmap?",
        answer: [
            "KompassAI has a robust development plan for the next year, aiming to add the following features:",
            "1. Deeper company insights",
            "2. Funding data",
            "3. Email warmup tool",
            "4. Engagement data",
            "5. Intent data",
            "6. IP data",
        ],
    },
];

export const WEBSITE_VISITORS_REPORT_ITEMS = {
    TOP_COMPANIES_BY_VISITS: "Top companies by visits",
    INDUSTRIES: "Industries",
    EMPLOYEE_RANGES: "Employee Ranges",
    LOCATIONS: "Locations",
};

export const ENRICHMENT_TYPE = {
    EMAIL_ONLY: "email",
    PHONE_ONLY: "phone",
    ALL: "all",
    DEEP_SEARCH: "deep",
};
