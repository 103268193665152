import * as React from "react";
import { useEffect, useState } from "react";
import SearchInput from "@/components/common/SearchInput";
import { SavedSearchesItem } from "@/components/search/SavedSearchesItem";
import useKompassSearch from "@/hooks/useKompassSearch";
import { toast } from "react-toastify";

export const SavedSearchesList = ({ savedFilters, setUpdateSearchFilters, handleSetFilters }) => {
    const [searchValue, setSearchValue] = useState("");
    const [filtersToShow, setFiltersToShow] = useState(savedFilters);
    const { saveSearchFilters, deleteSavedSearchFilter } = useKompassSearch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (searchValue.trim()) {
            const regex = new RegExp(searchValue, "i"); // Case-insensitive regex
            setFiltersToShow(savedFilters.filter((filter) => regex.test(filter.title)));
        } else {
            setFiltersToShow(savedFilters);
        }
    }, [searchValue, savedFilters]);

    const handleDeleteItem = async (id) => {
        setIsLoading(true);
        const res = await deleteSavedSearchFilter(id);

        if (!res.status) {
            toast.error(res.message);
        }

        setUpdateSearchFilters((prev) => !prev);
        setIsLoading(false);
    };

    const handleDuplicateItem = async (item) => {
        setIsLoading(true);
        const res = await saveSearchFilters({
            ...item,
            title: `${item.title} (Duplicate)`,
        });

        if (!res.status) {
            toast.error(res.message);
        }

        setUpdateSearchFilters((prev) => !prev);
        setIsLoading(false);
    };

    return (
        <div className="w-full flex flex-col gap-1 pb-6">
            <SearchInput
                value={searchValue}
                placeholder="Find Saved Search"
                containerStyles="!w-full !max-w-full"
                onInputChanged={(searchFilter) => {
                    setSearchValue(searchFilter);
                }}
            />
            {filtersToShow.map((savedItem) => {
                const entriesObjects = Object.entries(savedItem.filters);

                return (
                    <SavedSearchesItem
                        entriesObjects={entriesObjects}
                        savedItem={savedItem}
                        handleDeleteItem={handleDeleteItem}
                        handleDuplicateItem={handleDuplicateItem}
                        isLoading={isLoading}
                        handleSetFilters={handleSetFilters}
                    />
                );
            })}
        </div>
    );
};
