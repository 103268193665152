import React, { useState, useRef } from "react";
import FilterSection from "./FilterSection";
import ActionButtons from "./ActionButtons";
import CompanySize from "@/assets/image/icp-filters/company-size.svg";
import AreaChart from "@/assets/image/icp-filters/area_chart.svg";
import Location from "@/assets/image/icp-filters/assistant_navigation.svg";
import useICPFilters from "@/hooks/useICPFilters";
import { AccordianComponent } from "@/components/search/AccordianComponents";
import FilterAutocomplete from "@/components/common/FilterAutocomplete";
import { toast } from "react-toastify";
import DepartmentIcon from "@/assets/image/search/department.svg";
import BuildIcon from "@/assets/image/search/build.svg";
import { countryList, departmentList, industryList, levelList, numberOfEmployeesList } from "@/utils/filter";

// const filterSections = [
//     { label: 'Company size', id: 'size', src: companySize },
//     { label: 'Industry', id: 'industry', src: areaChart },
//     { label: 'Location', id: 'location', src: location }
// ];

function ICPFilter({ filterOptions, iCPFilters, setICPFilters, level, setLevel, department, setDepartment }) {
    const [filterName, setFilterName] = useState(null);
    const [size, setSize] = useState(null);
    const [industry, setIndustry] = useState(null);
    const [location, setLocation] = useState(null);
    const { createICPFilter } = useICPFilters();

    const filterDuplicateValues = (arr) => {
        return arr.filter((obj, index, self) => self.findIndex((obj2) => obj2?.label == obj?.label) === index);
    };

    const employeesList = filterDuplicateValues([...filterOptions?.size, ...numberOfEmployeesList]);
    const filterIndustry = filterDuplicateValues([...filterOptions?.industry, ...industryList]);
    const locationList = filterDuplicateValues([...filterOptions?.location, ...countryList]);

    const saveICPFilter = async () => {
        if (filterName && (size || industry || location || department || level)) {
            const response = await createICPFilter({
                name: filterName,
                size: size?.label,
                industry: industry?.label,
                location: location?.label,
                department,
                level,
            });
            if (response) {
                toast.success("New ICP Added successfully");
                const tempFilters = iCPFilters;
                tempFilters.push({
                    name: filterName,
                    size: size?.label,
                    industry: industry?.label,
                    location: location?.label,
                    department,
                    level,
                });
                setICPFilters(tempFilters);
            } else {
                toast.error("Failed to add new ICP");
            }
        }
    };

    const discardChanges = () => {
        setFilterName(null);
        setSize(null);
        setIndustry(null);
        setLocation(null);
    };

    return (
        <section className="px-10 flex flex-col">
            <h1 className="text-2xl font-medium leading-tight text-stone-950 max-md:max-w-full">Edit your ICP filter</h1>
            <div className="flex flex-col mt-12 w-4/5 max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-wrap items-center pb-6 w-full text-sm leading-snug border-b border-black border-opacity-10 max-md:max-w-full">
                        <div className="flex flex-col grow shrink self-stretch my-auto min-h-[46px] w-[175px]">
                            <label htmlFor="filterName" className="flex-1 shrink self-stretch w-full text-black">
                                <span className="font-semibold">Name</span>
                                <span className="font-semibold text-red-600" aria-hidden="true">
                                    *
                                </span>
                            </label>
                            <div className="flex-1 mt-1.5 text-neutral-400">Give Your filter a name</div>
                        </div>
                        <input
                            id="filterName"
                            type="text"
                            className="flex pl-4 grow shrink gap-2.5 self-stretch my-auto rounded-md border border-solid bg-stone-50 border-stone-200 h-[54px] min-w-[240px] w-[841px]"
                            required
                            aria-required="true"
                            value={filterName}
                            onChange={(e) => {
                                setFilterName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-wrap items-start mt-6 w-full max-md:max-w-full pb-4 border-b">
                        <div className="flex flex-col grow shrink text-sm leading-snug w-[175px]">
                            <div className="text-black">
                                <span className="font-semibold">Company Filters</span>
                                {/* <span className="font-semibold text-red-600" aria-hidden="true">*</span> */}
                            </div>
                            <div className="mt-1 text-neutral-400">Define and build your filter.</div>
                        </div>
                        <div className="flex flex-col grow shrink min-w-[240px] w-[843px] max-md:max-w-full">
                            <AccordianComponent
                                title="Size"
                                icon={CompanySize}
                                isAddBorder={false}
                                // expand={isFromSavedFiltersPage && country?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        clearTrigger={false}
                                        value={size}
                                        options={employeesList}
                                        onFilterChange={(v) => {
                                            setSize(v);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Industry"
                                icon={AreaChart}
                                isAddBorder={false}
                                // expand={isFromSavedFiltersPage && country?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        clearTrigger={false}
                                        value={industry}
                                        options={filterIndustry}
                                        onFilterChange={(v) => {
                                            setIndustry(v);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            <AccordianComponent
                                title="Location"
                                icon={Location}
                                isAddBorder={false}
                                // expand={isFromSavedFiltersPage && country?.length}
                            >
                                <div className="mt-1 w-full">
                                    <FilterAutocomplete
                                        clearTrigger={false}
                                        value={location}
                                        options={locationList}
                                        onFilterChange={(v) => {
                                            setLocation(v);
                                        }}
                                    />
                                </div>
                            </AccordianComponent>
                            {/* <FilterSection label="Company Size" src={CompanySize} options={filterOptions.size} setOption={setSize} />
                            <FilterSection label="Industry" src={AreaChart} options={filterOptions.industry} setOption={setIndustry} />
                            <FilterSection label="Location" src={Location} options={filterOptions.location} setOption={setLocation} /> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap items-start mt-6 w-full max-md:max-w-full">
                    <div className="flex flex-col grow shrink text-sm leading-snug w-[175px]">
                        <div className="text-black">
                            <span className="font-semibold">Job Filters</span>
                            {/* <span className="font-semibold text-red-600" aria-hidden="true">*</span> */}
                        </div>
                        <div className="mt-1 text-neutral-400">Define and build your filter.</div>
                    </div>
                    <div className="flex flex-col grow shrink min-w-[240px] w-[843px] max-md:max-w-full">
                        <AccordianComponent
                            title="Job Level"
                            icon={DepartmentIcon}
                            isAddBorder={false}
                            // expand={isFromSavedFiltersPage && level?.length}
                        >
                            <div className="mt-1 w-full">
                                <FilterAutocomplete
                                    multiple={true}
                                    clearTrigger={false}
                                    value={level}
                                    options={levelList}
                                    onFilterChange={(value) => {
                                        setLevel(value);
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        <AccordianComponent
                            title="Department"
                            icon={BuildIcon}
                            isAddBorder={false}
                            // expand={isFromSavedFiltersPage && jobDepartment?.length}
                        >
                            <div className="mt-1 w-full">
                                <FilterAutocomplete
                                    multiple={true}
                                    value={department}
                                    options={departmentList}
                                    onFilterChange={(selectedDepartment) => {
                                        setDepartment(selectedDepartment);
                                        console.log(department);
                                    }}
                                />
                            </div>
                        </AccordianComponent>
                        {/* <FilterSection label="Company Size" src={CompanySize} options={filterOptions.size} setOption={setSize} />
                            <FilterSection label="Industry" src={AreaChart} options={filterOptions.industry} setOption={setIndustry} />
                            <FilterSection label="Location" src={Location} options={filterOptions.location} setOption={setLocation} /> */}
                    </div>
                </div>
                <ActionButtons saveICPFilter={saveICPFilter} discardChanges={discardChanges} />
            </div>
        </section>
    );
}

export default ICPFilter;
