import React, { useEffect, useState } from "react";
import { CircularProgress, Pagination } from "@mui/material";
import AllTabs from "@/components/ai_engagement/AllTabs";
import BaseContainer from "@/components/BaseContainer";
import MainLayout from "@/components/MainLayout";
import MainTitleBar from "@/components/MainTitleBar";
import { useNavigate } from "react-router-dom";
import useAIEngagement from "@/hooks/useAIEngagement";
import CampaignRow from "@/components/ai_engagement/campaignsTab/CampaignRow";
import NoMailBoxButton from "@/components/ai_engagement/NoMailBoxButton";

export default function Campaigns() {
    const navigate = useNavigate();
    const { getCampaignsAnalytics, getEmailsData } = useAIEngagement();
    const [campaignsData, setCampaignsData] = useState([]);
    const [isNoMailBox, setIsNoMailBox] = useState(false);
    const [loading, setLoading] = useState({ page: true });
    const [page, setPage] = useState(1);
    const rowsPerPage = 10;

    const fetchData = async () => {
        setLoading((prev) => ({ ...prev, page: true }));
        const response = await getCampaignsAnalytics();
        if (response.status) {
            setCampaignsData(response.data);
        }
        setLoading((prev) => ({ ...prev, page: false }));
    };

    const fetchEmailList = async () => {
        setLoading((prev) => ({ ...prev, page: true }));
        try {
            const listData = await getEmailsData();
            if (listData.data && !listData.data.length > 0) {
                setIsNoMailBox(true);
            }
        } catch (error) {
            console.log("error", error);
        }
        setLoading((prev) => ({ ...prev, page: false }));
    };

    useEffect(() => {
        fetchEmailList();
        fetchData();
    }, []);

    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const paginatedData = Array.isArray(campaignsData) ? campaignsData.slice((page - 1) * rowsPerPage, page * rowsPerPage) : [];
    const startItem = (page - 1) * rowsPerPage + 1;
    const endItem = Math.min(page * rowsPerPage, campaignsData?.length);

    return (
        <MainLayout>
            <MainTitleBar>
                <p>AI Engagement</p>
            </MainTitleBar>

            <div className="w-full bg-white mt-4 pb-10 ">
                <BaseContainer>
                    <AllTabs />
                    {loading.page ? (
                        <div className="flex justify-center items-center h-[500px]">
                            <CircularProgress />
                        </div>
                    ) : isNoMailBox ? (
                        <NoMailBoxButton />
                    ) : (
                        <div className="relative overflow-x-auto  mt-3 custom-scrollbar-section">
                            <table className="w-full text-left rtl:text-right min-w-[880px]">
                                <thead className="bg-dgray-100 py-3">
                                    <tr>
                                        <th scope="col" className="px-4 py-3 rounded-tl-lg rounded-bl-lg">
                                            Sending Account
                                        </th>
                                        <th className="px-4 py-2">Report</th>
                                        <th colSpan={6} className="rounded-tr-lg rounded-br-lg">
                                            <div className="flex">
                                                <div
                                                    onClick={() => navigate("/ai-engagement/create-campaign")}
                                                    className="ml-auto mr-5 text-sm cursor-pointer hover:bg-dgray-200 gap-1 flex items-center w-auto rounded-md border my-1 border-black py-0.5 px-3"
                                                >
                                                    <span className="text-lg">+</span>Create New Campaign
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData?.length === 0 ? (
                                        <tr>
                                            <td colSpan={8} className="text-center py-5 h-[500px]">
                                                No campaigns found
                                            </td>
                                        </tr>
                                    ) : (
                                        paginatedData.map((row, index) => (
                                            <CampaignRow
                                                key={index}
                                                id={row.campaignId}
                                                title={row.campaignName}
                                                status={row.campaignStatus}
                                                createdAt={row.createdAt}
                                                analytics={row.analytics}
                                                setCampaignsData={setCampaignsData}
                                                ctaLink={row.ctaLink}
                                                campaignsData={campaignsData}
                                            />
                                        ))
                                    )}
                                </tbody>
                            </table>
                            {campaignsData?.length > 0 && !loading.page && (
                                <div className="flex items-center gap-4 mt-7 mb-16 ">
                                    <p className="whitespace-nowrap">
                                        Showing {startItem}-{endItem} of {campaignsData?.length} items
                                    </p>
                                    <Pagination
                                        count={Math.ceil(campaignsData?.length / rowsPerPage)}
                                        page={page}
                                        onChange={handleChangePage}
                                        sx={{
                                            ml: "auto",
                                            "& .MuiPaginationItem-root": {
                                                "&.Mui-selected": {
                                                    bgcolor: "#000",
                                                    color: "#fff",
                                                    "&:hover": {
                                                        bgcolor: "#000",
                                                    },
                                                },
                                            },
                                            "& .MuiPagination-ul": {
                                                display: "flex",
                                                flexWrap: "nowrap",
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </BaseContainer>
            </div>
        </MainLayout>
    );
}
